import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const LoanPrograms = ({ heading, headingLevel, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section
      className={`relative pt-5 pb-12 md:pt-36 md:pb-22 ${
        className || "mb-20 md:mb-32"
      }`}
    >
      <div className="absolute left-0 top-0 h-2/6 w-full bg-primary-900 md:h-full md:w-2/5"></div>
      <div className="absolute right-0 bottom-0 h-4/6 w-full bg-secondary-600/10 md:top-0 md:h-full md:w-3/5"></div>
      <div className="container relative">
        <div className="grid items-center gap-y-5 gap-x-10 md:grid-cols-2 lg:gap-x-18">
          <div>
            <StaticImage
              src="../../images/1.0 Homepage/Purchase.png"
              loading="lazy"
              width={585}
            />
          </div>
          <div>
            <HeadingTag>{heading || "I Want To Buy a Home"}</HeadingTag>
            <p>
              Lower your monthly payment, cash out equity, get a better interest
              rate, change the terms—refinancing sure has a lot of benefits.
            </p>
            <div className="heading-six">Your Loan Options</div>
            <div className="flex flex-col space-y-1.5 md:flex-row md:space-x-16 md:space-y-0">
              <div className="flex flex-col space-y-1.5">
                <Link to="/conventional-home-loans/" className="no-underline">
                  Conventional Loans
                </Link>
                <Link to="/fha-loans-pasadena/" className="no-underline">
                  FHA Loans
                </Link>
                <Link to="/va-loans-los-angeles/" className="no-underline">
                  VA Loans
                </Link>
                <Link to="/jumbo-loans/" className="no-underline">
                  Jumbo Loans
                </Link>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Link to="/non-qm-loans/" className="no-underline">
                  Non QM Loans
                </Link>
                <Link to="/dscr-investor-home-loans/" className="no-underline">
                  Investors
                </Link>
                <Link to="/1031-loans/" className="no-underline">
                  1031 Exchange
                </Link>
                <Link to="/self-employed-home-loans/" className="no-underline">
                  Self Employed
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoanPrograms;
